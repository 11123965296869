import request from '../request'

export function fetchList (params) {
    return request({
        url: '/ai-service/version/app/list',
        params
    })
}

export function add (data) {
    return request({
        url: '/ai-service/version/app/add',
        method: 'POST',
        data,
    })
}

export function edit (data) {
    return request({
        url: '/ai-service/version/app/update',
        method: 'POST',
        data,
    })
}

export function remove (data) {
    return request({
        url: '/ai-service/version/app/del',
        method: 'POST',
        data
    })
}